import React, { useState, useEffect } from 'react';
import {
  Book, Link2, Server, Mail, Zap, Copy,
  Shield, Globe, MessageSquare,
  Cpu, CircuitBoard, HardDrive, Wifi, Battery, Moon, Sun
} from 'lucide-react';
import { Alert, AlertDescription } from './components/ui/alert/Alert';

// Internationalization Content
const translations = {
  en: {
    notification: "We're growing! You're very welcome to open a channel with us!!",
    title: 'HitHow Node',
    about: {
      heading: 'About HitHow Node',
      description: 'HitHow is a dedicated Lightning Network node committed to providing robust and reliable connectivity for low-cost, high-speed Bitcoin transactions. Our goal is to contribute to the decentralized infrastructure and promote Bitcoin adoption as a payment method.'
    },
    addresses: {
      clearnet: 'Clearnet',
      tor: 'Tor'
    },
    infrastructure: {
      heading: 'Node Infrastructure',
      processor: 'AMD Ryzen 7 Processor',
      memory: '32 GB RAM',
      storage: '2TB SSD NVME',
      network: 'Dual High-Speed Internet Links',
      power: 'UPS Protected Power Supply'
    },
    amboss: {
      title: 'Node on Amboss',
      link: 'View on Amboss'
    },
    contact: {
      heading: 'Contact Information',
      email: 'Email',
      telegram: 'Telegram',
      nostr: 'Nostr'
    },
    copied: 'Copied!'
  },
  pt: {
    notification: "Estamos crescendo! Você é muito bem-vindo a abrir um canal conosco!!",
    title: 'HitHow Node',
    about: {
      heading: 'Sobre o Node HitHow',
      description: 'O HitHow é um node dedicado na Lightning Network, comprometido em fornecer conectividade robusta e confiável para transações Bitcoin de baixo custo e alta velocidade. Nosso objetivo é contribuir para a infraestrutura descentralizada e promover a adoção do Bitcoin como meio de pagamento.'
    },
    addresses: {
      clearnet: 'Clearnet',
      tor: 'Tor'
    },
    infrastructure: {
      heading: 'Infraestrutura do Node',
      processor: 'Processador AMD Ryzen 7',
      memory: '32 GB RAM',
      storage: '2TB SSD NVME',
      network: 'Dois links rápidos de Internet',
      power: 'Alimentação protegida por no-break'
    },
    amboss: {
      title: 'Node no Amboss',
      link: 'Visualizar no Amboss'
    },
    contact: {
      heading: 'Informações de Contato',
      email: 'E-mail',
      telegram: 'Telegram',
      nostr: 'Nostr'
    },
    copied: 'Copiado!'
  },
  es: {
    notification: "¡Estamos creciendo! ¡Eres bienvenido a abrir un canal con nosotros!",
    title: 'HitHow Node',
    about: {
      heading: 'Sobre el Nodo HitHow',
      description: 'HitHow es un nodo dedicado en la Lightning Network, comprometido a proporcionar conectividad robusta y confiable para transacciones de Bitcoin de bajo costo y alta velocidad. Nuestro objetivo es contribuir a la infraestructura descentralizada y promover la adopción de Bitcoin como medio de pago.'
    },
    addresses: {
      clearnet: 'Clearnet',
      tor: 'Tor'
    },
    infrastructure: {
      heading: 'Infraestructura del Nodo',
      processor: 'Procesador AMD Ryzen 7',
      memory: '32 GB RAM',
      storage: '2TB SSD NVME',
      network: 'Dos enlaces rápidos de Internet',
      power: 'Alimentación protegida por UPS'
    },
    amboss: {
      title: 'Nodo en Amboss',
      link: 'Ver en Amboss'
    },
    contact: {
      heading: 'Información de Contacto',
      email: 'Correo',
      telegram: 'Telegram',
      nostr: 'Nostr'
    },
    copied: '¡Copiado!'
  }
};

const HitHowNodePage = () => {
  const [language, setLanguage] = useState('en');
  const [copiedField, setCopiedField] = useState(null);
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const t = translations[language];

  const copyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  const NodeAddresses = () => {
    const addresses = [
      {
        type: t.addresses.clearnet,
        address: '025ddd534168f01915299316e362ec8c47aa9d166f4a00941f9866dc4de0d47cf9@198.23.134.75:9735',
        icon: <Globe size={18} className="text-green-500 mr-2" />
      },
      {
        type: t.addresses.tor,
        address: '025ddd534168f01915299316e362ec8c47aa9d166f4a00941f9866dc4de0d47cf9@z56g2fc76zew2xmng2katn4be6thifzsdowrpsksxka76fhencjselad.onion:9735',
        icon: <Shield size={18} className="text-purple-500 mr-2" />
      }
    ];

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {addresses.map((node) => (
          <div
            key={node.type}
            className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg flex items-center justify-between"
          >
            <div className="flex items-center">
              {node.icon}
              <div>
                <h3 className="font-semibold text-gray-800 dark:text-gray-200">{node.type}</h3>
                <p
                  className="text-sm text-gray-600 dark:text-gray-300 break-all"
                  title={node.address}
                >
                  {node.address === 'unavailable'
                    ? node.address
                    : `${node.address.slice(0, 6)}...${node.address.slice(-12)}`
                  }
                </p>
              </div>
            </div>
            <button
              onClick={() => copyToClipboard(node.address, node.type)}
              className="ml-2 text-gray-500 hover:text-blue-600 transition-colors"
              title="Copy address"
            >
              {copiedField === node.type ? (
                <span className="text-green-600 text-xs">{t.copied}</span>
              ) : (
                <Copy size={20} />
              )}
            </button>
          </div>
        ))}
      </div>
    );
  };

  const NodeInfrastructure = () => {
    const specs = [
      { icon: <Cpu size={20} className="text-blue-500" />, text: t.infrastructure.processor },
      { icon: <CircuitBoard size={20} className="text-green-500" />, text: t.infrastructure.memory },
      { icon: <HardDrive size={20} className="text-purple-500" />, text: t.infrastructure.storage },
      { icon: <Wifi size={20} className="text-yellow-500" />, text: t.infrastructure.network },
      { icon: <Battery size={20} className="text-red-500" />, text: t.infrastructure.power }
    ];

    return (
      <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4 flex items-center">
          <Server size={24} className="mr-3 text-blue-500" />
          {t.infrastructure.heading}
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {specs.map((spec, index) => (
            <div
              key={index}
              className="flex items-center space-x-3 p-3 bg-white dark:bg-gray-600 rounded-lg shadow-sm"
            >
              {spec.icon}
              <span className="text-gray-700 dark:text-gray-200">{spec.text}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ContactInfo = () => {
    const contacts = [
      {
        type: t.contact.email,
        value: 'admin@hithow.com',
        icon: <Mail size={20} className="text-red-500 mr-2" />,
        link: 'mailto:admin@hithow.com'
      },
      // {
      //   type: t.contact.telegram,
      //   value: '@hithownode',
      //   icon: <MessageCircle size={20} className="text-blue-500 mr-2" />,
      //   link: 'https://t.me/hithownode'
      // },
      {
        type: t.contact.nostr,
        value: 'npub1vnpwtv3vqydpz7g4380cs00ennpzedd48n3n9ee68384uzyys3dqr5m9aq',
        icon: <MessageSquare size={20} className="text-purple-500 mr-2" />,
        link: '#'
      }
    ];

    return (
      <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4 flex items-center">
          <Book size={24} className="mr-3 text-purple-500" />
          {t.contact.heading}
        </h3>

        <div className="space-y-3">
          {contacts.map((contact) => (
            <div
              key={contact.type}
              className="flex items-center justify-between"
            >
              <a
                href={contact.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-700 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors"
              >
                {contact.icon}
                <span className="mr-2">{contact.type}:</span>
                <span title={contact.value}>
                  {contact.type === t.contact.nostr
                    ? `${contact.value.slice(0, 6)}...${contact.value.slice(-6)}`
                    : contact.value
                  }
                </span>
              </a>
              <button
                onClick={() => copyToClipboard(contact.value, contact.type)}
                className="text-gray-500 hover:text-blue-600 transition-colors"
                title="Copy contact"
              >
                {copiedField === contact.type ? (
                  <span className="text-green-600 text-xs">{t.copied}</span>
                ) : (
                  <Copy size={18} />
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const LanguageSwitcher = () => {
    const flags = [
      { code: 'en', icon: '🇺🇸' },
      { code: 'pt', icon: '🇧🇷' },
      { code: 'es', icon: '🇪🇸' }
    ];

    return (
      <div className="flex space-x-2">
        {flags.map((flag) => (
          <button
            key={flag.code}
            onClick={() => setLanguage(flag.code)}
            className={`text-3xl ${language === flag.code ? 'opacity-50 cursor-default' : 'hover:scale-110 transition-transform'}`}
            disabled={language === flag.code}
          >
            {flag.icon}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 flex flex-col relative">
      <Alert className="rounded-none border-l-0 border-r-0 border-t-0 bg-blue-50 dark:bg-blue-950 border-blue-200 dark:border-blue-800">
        <AlertDescription className="text-center text-blue-700 dark:text-blue-300">
          {t.notification}
        </AlertDescription>
      </Alert>

      <header className="bg-white dark:bg-gray-800 shadow-md py-6">
        <div className="container mx-auto px-4 flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Zap color="#EAB306" size={36} />
            <h1 className="text-2xl font-bold text-gray-800 dark:text-gray-100">{t.title}</h1>
          </div>
          <LanguageSwitcher />
        </div>
      </header>

      <main className="container mx-auto px-4 py-12 flex-grow">
        <div className="max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <div className="p-8">
            <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-100 mb-6">
              {t.about.heading}
            </h2>

            <div className="space-y-6 text-gray-600 dark:text-gray-300">
              <p className="leading-relaxed">
                {t.about.description}
              </p>

              <NodeAddresses />

              <NodeInfrastructure />

              <div className="grid md:grid-cols-2 gap-4">
                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg flex items-center space-x-4">
                  <Link2 size={24} className="text-green-500" />
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-gray-200">{t.amboss.title}</h3>
                    <a
                      href="https://amboss.space/node/025ddd534168f01915299316e362ec8c47aa9d166f4a00941f9866dc4de0d47cf9"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-600 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                      {t.amboss.link}
                    </a>
                  </div>
                </div>
              </div>

              <ContactInfo />
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-white dark:bg-gray-800 py-6 shadow-md">
        <div className="container mx-auto px-4 text-center text-gray-500 dark:text-gray-400 flex items-center justify-center space-x-4">
          <span>© {new Date().getFullYear()} HitHow Lightning Network Node</span>
          <button
            onClick={toggleTheme}
            className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
          >
            {theme === 'dark' ? (
              <Sun size={20} className="text-yellow-500" />
            ) : (
              <Moon size={20} className="text-gray-500" />
            )}
          </button>
        </div>
      </footer>
    </div>
  );
};

export default HitHowNodePage;